import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faEnvelope, 
  faPhoneAlt,
  faSignInAlt,
  faSignOutAlt,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from "react-bootstrap";
import { BigPlayButton } from 'video-react';
import { 
  Modal,  
  Form,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useFilePicker } from 'use-file-picker';
import { UploadButton } from '@api.video/react-upload-button';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
// import '../../assets/scss/style.scss';

import VideoModal from '../../components/elements/VideoModal';
import Logo from "../../assets/images/logo.webp";

const firebaseConfig = {
  apiKey: "AIzaSyAjnR45TF1PJ16Xcve-ZExeGpBWb2dyTaQ",
  projectId: "t2g-app",
  databaseURL: "https://t2g-app.firebaseio.com",
  storageBucket: "t2g-app.appspot.com",
  appId: "1:667083673387:web:18d6d0247d84afa0b0ff6e",
  authDomain: "t2g-app.firebaseapp.com",
};
firebase.initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = firebase.auth();

function Home() {  
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [videos, setVideos] = useState([])
  const [video, setVideo] = useState(null)

  const [coach, setCoach] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [signing, setSigning] = useState(false);
  const [signingError, setSigningError] = useState('');
  const [uploadToken, setUploadToken] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: "video/*",
    multiple: false
  });

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is signed in');
        // setCoach(user);
        setShowLoginModal(false);

        getCoachInfo(user.uid)
      }
      else {
        console.log('User is signed out');
        setCoach(null);
        setVideos([]);
        setShowLoginModal(true);
      }
    })
    
    return () => {
      unlisten();
    }
  }, []);

  const getCoachInfo = async (coachId) => {
    let query = firebase.firestore()
      .collection('AssistantCoach')
      .doc(coachId)
    query.get().then((snapshot) => {
      if (!snapshot.exists) {
        handleSignOut()
        console.log('coach does not exist.....')
        return
      }
      let coachInfo = snapshot.data()
      coachInfo.uid = coachId
      // console.log('getCoachInfo', coachInfo)
      setCoach(coachInfo)

      // Get Team Videos
      getTeamVideos(coachId)

      // Get API.Video Upload Token
      getUploadToken()
    });
  }

  const getUploadToken = async () => {
    fetch("https://us-central1-t2g-app.cloudfunctions.net/fetchUploadToken", {
      method: 'POST',
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('getUploadToken Result', result)
          if (result && result.token) {
            setUploadToken(result.token)
          }
        },
        (error) => {
          console.log('us-central1-t2g-app Error', error)
        }
      )
  }

  const getTeamVideos = async (coachId) => {
    let query = firebase.firestore()
      .collection('Reel')
      .where("userId", "==", coachId)
      .where("type", "in", [0, 2])
    query.onSnapshot((snapshot) => {
      if (snapshot.empty) {
        console.log('No team video found!')
        return
      }

      var videos = [];
      snapshot.forEach((doc) => {
        videos.push(doc.data());
      });

      console.log('videos', videos)
      setVideos(videos)
    });
  } 

  const saveVideoToFirebase = async (video) => {
    var videoUrl = video.assets.mp4
    if (!videoUrl) {
      videoUrl = video.assets.player
    }
    var thumbnailUrl = video.assets.thumbnail
    var videoId = video.videoId

    let videoInfo = {
      'userId': coach.uid, 
      'thumUrl': thumbnailUrl,
      'videoUrl': videoUrl,
      'title': video.title,
      'type': 0,
      'roleType': (coach.headCoach ? 2: 1),
      'id': videoId,      
    }

    let ref = firebase.firestore().collection('Reel')
    ref.add(videoInfo)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  const handleLoginModalClose = () => setShowLoginModal(false);

  const handleLogin = async () => {    
    if (!email || !pin) {
      return;
    }
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(email) === false) {
      console.log('invalid email address...')
      return;
    }
    
    setSigning(true)
    firebase.auth().signInWithEmailAndPassword(email, pin)
      .then((userCredential) => {
        setSigning(false)
        // Signed in
        var user = userCredential.user;
        console.log('login success', user)
      })
      .catch((error) => {
        setSigning(false)
        console.log('login error', error)
        var errorMsg = 'Something went wrong...'
        if (error.code == 'auth/user-not-found') {
          errorMsg = 'There is no existing user record corresponding to the provided identifier.'
        }
        else if (error.code == 'auth/invalid-email') {
          errorMsg = 'The provided value for the email user property is invalid. It must be a string email address.'
        }
        else if (error.code == 'auth/wrong-password') {
          errorMsg = 'The password is invalid or the user does not have a password.'
        }
        setSigningError(errorMsg)
      });
  };

  const handleSubmit = event => {
    // 👇️ prevent page refresh
    event.preventDefault();

    // console.log('form submitted ✅');
  };  

  const handleSignOut = async () => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      console.log('handleSignOut - success')
    }).catch((error) => {
      // An error happened.
      console.log('handleSignOut', error)
    });
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const openModal = (e, video) => {
    console.log('openModal...', e)
    e.preventDefault();

    setVideo(video);
    setVideomodalactive(true);
  }

  const loadVideos = () => {

    return (
      <div className='App-videos'>
        {videos.map((element, index) => (
          <div className='Video-container' key={index}>
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={(e) => openModal(e, element)}
            >
              <img
                className="has-shadow"
                src={element.thumUrl}
                alt="Hero"
              />
            </a>
            <p>{element.title}</p>
          </div>
        ))}
        {coach && (          
          <div className='Video-add'>
            {!uploading && (
              <UploadButton
                uploadToken={uploadToken}
                onUploadProgress={(progress) => {
                  setUploading(true)
                  setUploadProgress(Math.round((progress.uploadedBytes * 100) / progress.totalBytes))
                }}
                onUploadSuccess={(video) => {
                  setUploading(false)
                  getUploadToken()
                  // console.log('onUploadSuccess', video);
                  saveVideoToFirebase(video)
                }}
                onUploadError={(errorMessage) => {
                  setUploading(false)
                  getUploadToken()
                  console.log('onUploadError', errorMessage);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  background: 'transparent',
                  border: 'transparent',
                }}
              >
                <FontAwesomeIcon icon={faPlus} color={'#FFFFFF'} size={'2x'} />
              </UploadButton>
            )}
            {uploading && (
              <div>
                <p>{`${uploadProgress}% uploaded`}</p>
                <progress className='progress is-primary' value={uploadProgress} max='100'></progress>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="App">

      <div className="App-header">
        <div className='Header-container'>
          <a
            href="https://t2gapp.com/"
            target='_blank'
          >
            <img className='Header-logo' src={Logo} />
          </a>
          <div className='Header-info m-2'>
            <FontAwesomeIcon 
              icon={faPhoneAlt} 
              color={'#FFFFFF'}
              size={'1x'}
            />
            <p className='mb-0'>(256) 344-4130 (office)</p>
            <FontAwesomeIcon 
              icon={faEnvelope} 
              color={'#FFFFFF'}
              size={'1x'}
            />
            <a
              href='mailto:support@ticket2greatness.com'
            >
              <p className='mb-0'>support@ticket2greatness.com</p>
            </a>

            {coach && (
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={
                  <Tooltip id="tooltip-bottom">
                    Sign Out
                  </Tooltip>
                }
              >
                <a 
                  style={{
                    marginLeft: 20
                  }}
                  href='' 
                  onClick={(e) => {
                    e.preventDefault()
                    handleSignOut()
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} color={'#FFFFFF'} size={'1x'} />
                </a>
              </OverlayTrigger>
            )}

            {!coach && (
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={
                  <Tooltip id="tooltip-bottom">
                    Sign In
                  </Tooltip>
                }
              >
                <a 
                  style={{
                    marginLeft: 20
                  }}
                  href='' 
                  onClick={(e) => {
                    e.preventDefault()
                    setShowLoginModal(true)
                  }}
                >
                  <FontAwesomeIcon icon={faSignInAlt} color={'#FFFFFF'} size={'1x'} />
                </a>
              </OverlayTrigger>
            )}            
              
          </div>
        </div>
      </div>

      <div className='App-content mt-5'>

        {loadVideos()}

        <VideoModal
          id="video-modal"
          show={videoModalActive}
          handleClose={closeModal}
          video={video ? (video.streamUrl ? video.streamUrl: video.videoUrl): ''}//'https://embed.api.video/vod/vi5TiUAWv8PUzulhaxvMyBSN'
          videoTag={(video && video.streamUrl)? 'iframe': 'video'} //'iframe' // 'video' 
        />

      </div>      

      <Modal
        style={{
          zIndex: 1100
        }}
        show={showLoginModal}
        onHide={handleLoginModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              marginTop: 0,
              marginBottom: 0,
              color: '#000000',
            }}
          >
            Coach Log In
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="loginForm" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="form.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@ticket2greatness.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="form.ControlInput1">
              <Form.Label>PIN</Form.Label>
              <Form.Control
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                required
              />
            </Form.Group>
            <p className='text-danger'>{signingError}</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {signing && (
            <Spinner 
              animation="border" 
              variant="primary" 
              style={{
                marginRight: 20
              }}
            />
          )}
          {!signing && (
            <Button variant="primary" form="loginForm" type="submit" onClick={handleLogin}>
              Log In
            </Button>
          )}            
          <Button variant="secondary" onClick={handleLoginModalClose}>
            Cancel
          </Button>           
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Home;
